/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <div class="main-border rounded-lg vx-card mb-6">
          <div class="flex items-center justify-between p-5" style="border-bottom: 1px solid #181d2a">
            <h3 class="font-light self-center mr-2">{{ currentServer + 1 }}/{{ connectServers.length }}</h3>
            <div class="flex items-center">
              <vs-button
                :disabled="currentServer === 0"
                color="danger"
                class="mr-2"
                icon-pack="feather"
                icon="icon-arrow-left-circle"
                type="filled"
                @click="currentServer--"
                >Previous Server</vs-button
              >
              <vs-button
                :disabled="currentServer + 1 === connectServers.length"
                color="success"
                icon-pack="feather"
                icon="icon-arrow-right-circle"
                type="filled"
                @click="currentServer++"
                >Next Server</vs-button
              >
            </div>
          </div>
          <div class="">
            <div class="p-4">
              <h2>
                Name: <span class="font-light break-all">{{ connectServers[currentServer].name }}</span>
              </h2>

              <div class="grid grid-cols-2 gap-x-4 mt-3">
                <div class="mt-3">
                  <div class="flex items-center mb-1">
                    <feather-icon
                      v-if="connectServers[currentServer].roleIsCorrect === true"
                      icon="CheckCircleIcon"
                      svgClasses="w-6 h-6 text-success"
                      class="mr-1"
                    ></feather-icon>
                    <feather-icon
                      v-if="connectServers[currentServer].roleIsCorrect === false"
                      icon="XCircleIcon"
                      svgClasses="w-6 h-6 text-danger"
                      class="mr-1"
                    ></feather-icon>
                    <h6>Role</h6>
                  </div>
                  <v-select placeholder="Select Role" class="w-full" :options="roleOptions" v-model="connectServers[currentServer].role" />
                </div>

                <div class="mt-3">
                  <div class="flex items-center mb-1">
                    <feather-icon
                      v-if="connectServers[currentServer].ipIsCorrect === true"
                      icon="CheckCircleIcon"
                      svgClasses="w-6 h-6 text-success"
                      class="mr-1"
                    ></feather-icon>
                    <feather-icon
                      v-if="connectServers[currentServer].ipIsCorrect === false"
                      icon="XCircleIcon"
                      svgClasses="w-6 h-6 text-danger"
                      class="mr-1"
                    ></feather-icon>
                    <h6>IP Address</h6>
                  </div>
                  <vs-input placeholder="Enter IP Address" v-model="connectServers[currentServer].ip" class="w-full" />
                </div>

                <div class="mt-5">
                  <div class="flex items-center mb-1">
                    <feather-icon
                      v-if="connectServers[currentServer].serviceIsCorrect === true"
                      icon="CheckCircleIcon"
                      svgClasses="w-6 h-6 text-success"
                      class="mr-1"
                    ></feather-icon>
                    <feather-icon
                      v-if="connectServers[currentServer].serviceIsCorrect === false"
                      icon="XCircleIcon"
                      svgClasses="w-6 h-6 text-danger"
                      class="mr-1"
                    ></feather-icon>
                    <h6>Non-Compliant Service</h6>
                  </div>
                  <v-select
                    placeholder="Select Service"
                    class="w-full"
                    :options="nonCompliantServiceOptions"
                    v-model="connectServers[currentServer].service"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-lg vx-card overflow-hidden">
          <vue-command
            id="command_prompt"
            :commands="commands"
            :prompt="`[${activeUserInfo.firstName.toLowerCase().trim()}@ip-connect ~]>`"
            class="rounded"
            intro="Type [help] to show available commands"
            :show-intro="true"
            style="background-color: #111111"
            title="Technician Command Prompt"
          />
        </div>
      </div>
      <div class="vx-col w-1/2">
        <vx-card>
          <div class="flex flex-col justify-center items-center p-3">
            <h3 class="mb-4">Click To Expand Diagram</h3>
            <img
              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368820687520923648/sim-graphic.png"
              class="responsive cursor-pointer"
              alt=""
              v-viewer
            />
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';
import vSelect from 'vue-select';
import 'viewerjs/dist/viewer.css';
import { directive as viewer } from 'v-viewer';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      expandPage: false,
      consoleOpen: false,

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>nmap -host-<br>ping -host-<br>help'),
        nmap: () => undefined,
        ping: () => undefined,
      },

      ranPing: false,
      ranNmap: false,

      roleOptions: ['Switch', 'Database', 'Code VC Server', 'Mail Server', 'Web Server', 'File Server'],
      nonCompliantServiceOptions: [
        'IMAP(S) 993',
        'SMTP 25',
        'HTTP 80',
        'NetBIOS 139',
        'RPC 135',
        'FTP 21',
        'Telnet 23',
        'HTTPS 443',
        'IMAP 143',
        'SSH 22',
        'MYSQL 3306',
        'DNS 53',
        'SMB/CIFS 445',
      ],

      currentServer: 0,
      connectServers: [
        {
          name: 'connect-alfa.ip-connect.com',
          role: '',
          roleAnswer: 'File Server',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.0.100.51',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'FTP 21',
          serviceIsCorrect: null,
        },
        {
          name: 'connect-bravo.ip-connect.com',
          role: '',
          roleAnswer: 'Database',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.0.100.52',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'DNS 53',
          serviceIsCorrect: null,
        },
        {
          name: 'connect-charlie.ip-connect.com',
          role: '',
          roleAnswer: 'Mail Server',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.0.100.53',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'SMTP 25',
          serviceIsCorrect: null,
        },
        {
          name: 'connect-delta.ip-connect.com',
          role: '',
          roleAnswer: 'Code VC Server',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.0.100.54',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'RPC 135',
          serviceIsCorrect: null,
        },
        {
          name: 'connect-echo.ip-connect.com',
          role: '',
          roleAnswer: 'Switch',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.0.100.55',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'NetBIOS 139',
          serviceIsCorrect: null,
        },
        {
          name: 'connect-foxtrot.ip-connect.com',
          role: '',
          roleAnswer: 'Web Server',
          roleIsCorrect: null,
          ip: '',
          ipAnswer: '10.6.100.51',
          ipIsCorrect: null,
          service: '',
          serviceAnswer: 'IMAP 143',
          serviceIsCorrect: null,
        },
      ],
    };
  },
  directives: {
    viewer: viewer({
      debug: false,
    }),
  },
  methods: {
    pageExpand(expand) {
      if (expand === true) {
        this.expandPage = true;
        return;
      }
      setTimeout(() => {
        this.expandPage = false;
      }, 215);
    },
    toggleConsole() {
      if (this.consoleOpen === true) {
        this.consoleOpen = 'transition_out';
        return setTimeout(() => {
          this.consoleOpen = false;
        }, 400);
      }
      this.consoleOpen = true;
    },
    markSimulation() {
      let totalScore = 0;
      if (this.connectServers[0].role === this.connectServers[0].roleAnswer) {
        totalScore++;
        this.connectServers[0].roleIsCorrect = true;
      } else {
        this.connectServers[0].roleIsCorrect = false;
      }
      if (this.connectServers[0].ip === this.connectServers[0].ipAnswer) {
        totalScore++;
        this.connectServers[0].ipIsCorrect = true;
      } else {
        this.connectServers[0].ipIsCorrect = false;
      }
      if (this.connectServers[0].service === this.connectServers[0].serviceAnswer) {
        totalScore++;
        this.connectServers[0].serviceIsCorrect = true;
      } else {
        this.connectServers[0].serviceIsCorrect = false;
      }

      if (this.connectServers[1].role === this.connectServers[1].roleAnswer) {
        totalScore++;
        this.connectServers[1].roleIsCorrect = true;
      } else {
        this.connectServers[1].roleIsCorrect = false;
      }
      if (this.connectServers[1].ip === this.connectServers[1].ipAnswer) {
        totalScore++;
        this.connectServers[1].ipIsCorrect = true;
      } else {
        this.connectServers[1].ipIsCorrect = false;
      }
      if (this.connectServers[1].service === this.connectServers[1].serviceAnswer) {
        totalScore++;
        this.connectServers[1].serviceIsCorrect = true;
      } else {
        this.connectServers[1].serviceIsCorrect = false;
      }

      if (this.connectServers[2].role === this.connectServers[2].roleAnswer) {
        totalScore++;
        this.connectServers[2].roleIsCorrect = true;
      } else {
        this.connectServers[2].roleIsCorrect = false;
      }
      if (this.connectServers[2].ip === this.connectServers[2].ipAnswer) {
        totalScore++;
        this.connectServers[2].ipIsCorrect = true;
      } else {
        this.connectServers[2].ipIsCorrect = false;
      }
      if (this.connectServers[2].service === this.connectServers[2].serviceAnswer) {
        totalScore++;
        this.connectServers[2].serviceIsCorrect = true;
      } else {
        this.connectServers[2].serviceIsCorrect = false;
      }

      if (this.connectServers[3].role === this.connectServers[3].roleAnswer) {
        totalScore++;
        this.connectServers[3].roleIsCorrect = true;
      } else {
        this.connectServers[3].roleIsCorrect = false;
      }
      if (this.connectServers[3].ip === this.connectServers[3].ipAnswer) {
        totalScore++;
        this.connectServers[3].ipIsCorrect = true;
      } else {
        this.connectServers[3].ipIsCorrect = false;
      }
      if (this.connectServers[3].service === this.connectServers[3].serviceAnswer) {
        totalScore++;
        this.connectServers[3].serviceIsCorrect = true;
      } else {
        this.connectServers[3].serviceIsCorrect = false;
      }

      if (this.connectServers[4].role === this.connectServers[4].roleAnswer) {
        totalScore++;
        this.connectServers[4].roleIsCorrect = true;
      } else {
        this.connectServers[4].roleIsCorrect = false;
      }
      if (this.connectServers[4].ip === this.connectServers[4].ipAnswer) {
        totalScore++;
        this.connectServers[4].ipIsCorrect = true;
      } else {
        this.connectServers[4].ipIsCorrect = false;
      }
      if (this.connectServers[4].service === this.connectServers[4].serviceAnswer) {
        totalScore++;
        this.connectServers[4].serviceIsCorrect = true;
      } else {
        this.connectServers[4].serviceIsCorrect = false;
      }

      if (this.connectServers[5].role === this.connectServers[5].roleAnswer) {
        totalScore++;
        this.connectServers[5].roleIsCorrect = true;
      } else {
        this.connectServers[5].roleIsCorrect = false;
      }
      if (this.connectServers[5].ip === this.connectServers[5].ipAnswer) {
        totalScore++;
        this.connectServers[5].ipIsCorrect = true;
      } else {
        this.connectServers[5].ipIsCorrect = false;
      }
      if (this.connectServers[5].service === this.connectServers[5].serviceAnswer) {
        totalScore++;
        this.connectServers[5].serviceIsCorrect = true;
      } else {
        this.connectServers[5].serviceIsCorrect = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  mounted() {
    this.commands.ping = (x) => {
      // connect-alfa.ip-connect.com && 10.0.100.51
      if (x._[1] === 'connect-alfa.ip-connect.com') {
        return createStdout(
          'PING connect-alfa.ip-connect.com (10.0.100.51) 56(84) bytes of data.<br> 64 bytes from 10.0.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.51') {
        return createStdout(
          'PING 10.0.100.51 (10.0.100.51) 56(84) bytes of data.<br> 64 bytes from 10.0.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br> <br>',
        );
      }

      // connect-bravo.ip-connect.com & 10.0.100.52
      if (x._[1] === 'connect-bravo.ip-connect.com') {
        return createStdout(
          'PING connect-bravo.ip-connect.com (10.0.100.52) 56(84) bytes of data.<br> 64 bytes from 10.0.100.52: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.52: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.52: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.52 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.52') {
        return createStdout(
          'PING 10.0.100.52 (10.0.100.52) 56(84) bytes of data.<br> 64 bytes from 10.0.100.52: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.52: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.52: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.52 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // connect-charlie.ip-connect.com & 10.0.100.53
      if (x._[1] === 'connect-charlie.ip-connect.com') {
        return createStdout(
          'PING connect-charlie.ip-connect.com (10.0.100.53) 56(84) bytes of data.<br> 64 bytes from 10.0.100.53: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.53: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.53: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.53 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.53') {
        return createStdout(
          'PING 10.0.100.53 (10.0.100.53) 56(84) bytes of data.<br> 64 bytes from 10.0.100.53: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.53: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.53: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.53 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === 'connect-delta.ip-connect.com') {
        return createStdout(
          'PING connect-delta.ip-connect.com (10.0.100.54) 56(84) bytes of data.<br> 64 bytes from 10.0.100.54: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.54: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.54: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.54 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.54') {
        return createStdout(
          'PING 10.0.100.54 (10.0.100.54) 56(84) bytes of data.<br> 64 bytes from 10.0.100.54: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.54: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.54: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.54 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // connect-echo.ip-connect.com & 10.0.100.55
      if (x._[1] === 'connect-echo.ip-connect.com') {
        return createStdout(
          'PING connect-echo.ip-connect.com (10.0.100.55) 56(84) bytes of data.<br> 64 bytes from 10.0.100.55: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.55: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.55: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.55 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.0.100.55') {
        return createStdout(
          'PING 10.0.100.55 (10.0.100.55) 56(84) bytes of data.<br> 64 bytes from 10.0.100.55: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.0.100.55: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.0.100.55: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.0.100.55 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      // connect-foxtrot.ip-connect.com & 10.0.100.55
      if (x._[1] === 'connect-foxtrot.ip-connect.com') {
        return createStdout(
          'PING connect-foxtrot.ip-connect.com (10.6.100.51) 56(84) bytes of data.<br> 64 bytes from 10.6.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.6.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.6.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.6.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      if (x._[1] === '10.6.100.51') {
        return createStdout(
          '---Cm<br> PING 10.6.100.51 (10.6.100.51) 56(84) bytes of data.<br> 64 bytes from 10.6.100.51: icmp_seq=1 ttl=64 time=0.015 ms<br> 64 bytes from 10.6.100.51: icmp_seq=2 ttl=64 time=0.017 ms<br> 64 bytes from 10.6.100.51: icmp_seq=3 ttl=64 time=0.014 ms<br> <br> --- 10.6.100.51 ping statistics ---<br> 3 packets transmitted, 3 received, 0% packet loss, time 2051ms<br> rtt min/avg/max/mdev = 0.014/0.015/0.017/0.001 ms<br>',
        );
      }

      return createStderr('<span class="text-danger">‎ ‎ ‎  %‎‎ ‎‎ ‎Invalid Input.</span>');
    };
    this.commands.nmap = (x) => {
      // connect-alfa.ip-connect.com && 10.0.100.51
      if (x._[1] === 'connect-alfa.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-alfa.ip-connect.com (10.0.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 21/tcp   open     ftp         crushFTPd<br> 22/tcp   open     sftp          Solar Winds sftpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.51') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.51 (10.0.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 21/tcp   open     ftp         crushFTPd<br> 22/tcp   open     sftp          Solar Winds sftpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>>',
        );
      }

      // connect-bravo.ip-connect.com & 10.0.100.52
      if (x._[1] === 'connect-bravo.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-bravo.ip-connect.com (10.0.100.52)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 53/tcp   open msdns<br> 3306/tcp   open     mysql<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.52') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.52 (10.0.100.52)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 53/tcp   open msdns<br> 3306/tcp   open     mysql<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // connect-charlie.ip-connect.com & 10.0.100.53
      if (x._[1] === 'connect-charlie.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-charlie.ip-connect.com (10.0.100.53)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 587/tcp   open smtpd<br> 25/tcp   open smtpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.53') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.53 (10.0.100.53)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 587/tcp   open smtpd<br> 25/tcp   open smtpd<br> 135/tcp  filtered msrpc<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // connect-delta.ip-connect.com & 10.0.100.54
      if (x._[1] === 'connect-delta.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-delta.ip-connect.com (10.0.100.54)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open https Nginx/1.1 Gitlab Inc.<br> 135/tcp open msrpc<br> 3306/tcp filtered mysql<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.54') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-delta.ip-connect.com (10.0.100.54)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open https Nginx/1.1 Gitlab Inc.<br> 135/tcp open msrpc<br> 3306/tcp filtered mysql<br> 139/tcp  filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // connect-echo.ip-connect.com & 10.0.100.55
      if (x._[1] === 'connect-echo.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-echo.ip-connect.com (10.0.100.55)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open     https<br> 135/tcp  filtered msrpc<br> 139/tcp open netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.0.100.55') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.0.100.55 (10.0.100.55)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br> 443/tcp   open     https<br> 135/tcp  filtered msrpc<br> 139/tcp open netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      // connect-foxtrot.ip-connect.com & 10.6.100.51
      if (x._[1] === 'connect-foxtrot.ip-connect.com') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for connect-foxtrot.ip-connect.com (10.6.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br>443/tcp   open     https     Apache httpd 2.4.48 ((Ubuntu))<br> 143/tcp open imap<br> 139/tcp filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      if (x._[1] === '10.6.100.51') {
        return createStdout(
          'Starting Nmap 7.91 ( https://nmap.org ) at 2021-01-02 19:17 Eastern Standard Time<br> Nmap scan report for 10.6.100.51 (10.6.100.51)<br> Host is up (0.039s latency).<br> Not shown: 99 filtered ports<br> <br> PORT   STATE SERVICE VERSION<br>443/tcp   open     https     Apache httpd 2.4.48 ((Ubuntu))<br> 143/tcp open imap<br> 139/tcp filtered netbios-ssn<br> 445/tcp  filtered microsoft-ds<br> 1688/tcp filtered nsjtp-data<br> <br> Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br> Device type: general purpose<br> Running: Linux 4.X|5.X<br> OS CPE: cpe:/o:linux:linux_kernel:4 cpe:/o:linux:linux_kernel:5<br> OS details: Linux 4.15 - 5.6<br> Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br> <br> OS and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br> <br> Nmap done: 1 IP address (1 host up) scanned in 7.04 seconds<br> <br>',
        );
      }

      return createStderr('<span class="text-danger">‎ ‎ ‎  %‎‎ ‎‎ ‎Invalid Input.</span>');
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Prism,
    VueCommand,
    vSelect,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>
<style scoped>
. {
  z-index: 66;
}

.lineWidth01 {
  position: absolute;
  width: 66%;
  height: 5px;
  background-color: #ffffff;
  z-index: 0;
}
.lineWidth02 {
  position: absolute;
  width: 69px;
  margin-left: 12.1%;
  height: 5px;
  background-color: #e17055;
  z-index: 0;
}
.lineWidth03 {
  position: absolute;
  width: 69px;
  margin-right: 12.1%;
  height: 5px;
  background-color: #e17055;
  z-index: 0;
}

.lineHeight01 {
  position: absolute;
  width: 5px;
  height: 55px;
  margin-bottom: 49px;
  background-color: #ffffff;
  z-index: 0;
}

.lineHeight02 {
  position: absolute;
  width: 5px;
  height: 56px;
  margin-top: 49px;
  background-color: #ffffff;
  z-index: 0;
}

.lineHeight03 {
  width: 5px;
  height: 40px;
  background-color: #e17055;
  z-index: 0;
}
</style>
